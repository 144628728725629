import { logisticsService } from '@/api';
import { DialogMixin } from '@/mixins/dialog';
import { DeliveryModeEnum } from '@/resource/enum';
import { DeliveryShopList } from '@/resource/model';
import { translation } from '@/utils';
import { telOrMobile } from '@/utils/validate';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator';

type ShopForm = Partial<Omit<DeliveryShopList, 'id' | 'deliveryNoticeId'>> & {
  area: Array<string>;
};

@Component({})
export default class CustomSite extends mixins(DialogMixin) {
  @Prop({
    required: false,
    type: Object,
    default: null
  })
  public shopDelivery!: DeliveryShopList;

  public shopForm: ShopForm = {
    shopName: '',
    receiver: '',
    receiverTel: '',
    area: [],
    address: '',
    sendMethod: DeliveryModeEnum.TPL,
    remark: ''
  };

  public shopFormRules: { [prop in keyof ShopForm]: Array<Object> } = {
    shopName: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addDeliveryNotice.inputShopName')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    receiver: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addDeliveryNotice.inputReceiver')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    receiverTel: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addDeliveryNotice.inputReceiverTel')));
            return;
          }
          if (!telOrMobile(value)) {
            callback(new Error(translation('common.inputLegalTel')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    area: [
      {
        required: true,
        validator: (rule: any, value: Array<string>, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('addDeliveryNotice.selectArea')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    address: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value || value.length === 0) {
            callback(new Error(translation('addDeliveryNotice.inputAddress')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    sendMethod: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('projectShop.selectDeliveryMethod')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public deliveryModes = logisticsService.getDeliveryMode;

  public created(): void {
    if (!this.shopDelivery) {
      this.title = 'addDeliveryNotice.addDeliverySite';
      return;
    }
    this.title = 'addDeliveryNotice.editDeliverySite';
    Object.assign(this.shopForm, this.shopDelivery);
    this.shopForm.area = [this.shopForm.province!, this.shopForm.city!, this.shopForm.district!].filter(item => !!item);
  }

  public onSubmit(): void {
    (this.$refs.shopForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      const [province, city, district] = this.shopForm.area;
      this.shopForm.province = province || '';
      this.shopForm.city = city || '';
      this.shopForm.district = district || '';

      this.shopDelivery ? Object.assign(this.shopDelivery, this.shopForm) : this.$emit('add-success', this.shopForm);
      this.closeDialog();
    });
  }
}
