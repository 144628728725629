import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableOption, OsTableColumn } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import {
  DeliveryShopItemRelList,
  DeliveryShopList,
  ProductDeliveryCount,
  ProjectProductDelivery
} from '@/resource/model';
import { limitFutureForTimePicker, messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator';
import SelectProjectProduct from './select-project-product/select-project-product.vue';
import deliveryNoticeService from '@/api/logistics-management/delivery-notice';
import { ElInput } from 'element-ui/types/input';
import BatchEdit from '../batch-edit/batch-edit.vue';

@Component({
  components: { SelectProjectProduct, BatchEdit }
})
export default class ProductDeliveryDetails extends mixins(PagingMixin) {
  @PropSync('productDeliveries', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<DeliveryShopItemRelList>;

  public batchEditVisible: boolean = false;

  public tableOptions: OsTableOption<Partial<DeliveryShopItemRelList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    rowKey: () => {
      return 'itemId';
    }
  };

  public columnOptions: Array<OsTableColumn<DeliveryShopItemRelList>> = [
    {
      type: 'selection',
      prop: 'itemId',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'itemCode',
      label: 'projectProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'pointName',
      label: 'projectProduct.pointName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'projectProduct.platformProduct',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'projectProduct.customerProduct',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'projectProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishHeight',
      label: 'projectProduct.finishSize',
      showOverflowTooltip: true,
      minWidth: '180px',
      formatter: (row: Object): string => {
        return `${(row as DeliveryShopItemRelList).finishWidth} × ${(row as DeliveryShopItemRelList).finishHeight}`;
      }
    },
    {
      prop: 'productCount',
      label: 'projectProduct.count',
      minWidth: '100px',
      showOverflowTooltip: true
    },
    {
      prop: 'remainCount',
      label: 'addDeliveryNotice.remainCount',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'addDeliveryNotice.sendCount',
      minWidth: '180px'
    },
    {
      prop: 'planDeliveryTime',
      label: 'projectShop.plannedShippingTime',
      minWidth: '180px'
    },
    {
      prop: 'remark',
      label: 'projectProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.selectProduct',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      permissionCode: '',
      handleClick: (): void => {
        this.openAddProductDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'delivery:notice:deleteShopItemRel',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteProduct();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.batchEdit',
      operationType: 'batchEdit',
      icon: 'el-icon-edit',
      plain: true,
      handleClick: (): void => {
        this.openBatchEdit();
      }
    }
  ];

  public selectedProducts: Array<DeliveryShopItemRelList> = [];

  public addProductDialog = false;

  public productDelivery: DeliveryShopItemRelList | null = null;

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  /**
   * 产品项次剩余可发货数量
   */
  @PropSync('productDeliveryRecord', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  private productDeliveryCount!: Array<ProductDeliveryCount>;

  public created(): void {
    if (this.tableData.length > 0) {
      this.tableData.forEach(x => {
        this.initDynamicProps(x);
      });
      this.pagingData();
    }
  }

  public pagingData(): void {
    this.totalData = this.tableData.length;
    this.tableOptions.data = this.tableData.slice(
      (this.paging.currentPage - 1) * this.paging.showCount,
      this.paging.currentPage * this.paging.showCount
    );
  }

  public handleProductSelection(products: Array<DeliveryShopItemRelList>): void {
    this.selectedProducts = products;
  }

  public handleAddProductConfirm(products: Array<ProjectProductDelivery>): void {
    // 去除已添加的
    const itemIds = this.tableData.map(x => x.itemId);

    const toAddProducts = products
      .filter(x => !itemIds.includes(x.itemId))
      .map(x => {
        const obj = {
          ...x,
          planDeliveryTime: x.requiredDeliveryTime,
          // 发货数量默认为可发货的最大数量
          count: x.count
        } as any;

        // 新增产品处理逻辑
        this.$emit('add-shop-product', obj);

        this.initDynamicProps(obj);
        return obj;
      });

    this.tableData.unshift(...((toAddProducts as any) as Array<DeliveryShopItemRelList>));
    // 添加完产品项次后回到第一页
    this.paging.currentPage = 1;
    this.pagingData();
  }

  public handleSendCountClick(deliveryProduct: DeliveryShopItemRelList & { showSendCountInput: boolean }): void {
    deliveryProduct.showSendCountInput = true;
    this.$nextTick(() => {
      (this.$refs.sendCountInput as ElInput).focus();
    });
  }

  /**
   * 记录待编辑产品前次填写数量
   */
  public handleSendCountFocus(deliveryProduct: DeliveryShopItemRelList & { showSendCountInput: boolean }): void {
    deliveryProduct.previousCount = deliveryProduct.count;
  }

  /**
   * 验证待编辑产品输入数量并更新为正确数量
   */
  public handleSendCountBlur(deliveryProduct: DeliveryShopItemRelList & { showSendCountInput: boolean }): void {
    if (!deliveryProduct.count || deliveryProduct.count < 0) {
      deliveryProduct.count = 1;
    }

    if (deliveryProduct.count > deliveryProduct.remainCount + deliveryProduct.previousCount) {
      Message.error(`${translation('addDeliveryNotice.remainDeliveryCount')}${deliveryProduct.remainCount + deliveryProduct.previousCount}`);
      deliveryProduct.count = deliveryProduct.remainCount + deliveryProduct.previousCount;
    }
    
    const product = this.productDeliveryCount.find(item => item.itemId === deliveryProduct.itemId);
    if(product) {
      product.sentCount = product.sentCount - deliveryProduct.previousCount + deliveryProduct.count;
      deliveryProduct.remainCount = product.total - product.sentCount;
    }

    deliveryProduct.showSendCountInput = !deliveryProduct.showSendCountInput;
  }

  public handleDatePickerClick(rowData: { planDeliveryTimeInput: boolean }): void {
    rowData.planDeliveryTimeInput = true;
  }

  public handleDatePickerBlur(rowData: DeliveryShopItemRelList & { planDeliveryTimeInput: boolean }): void {
    rowData.planDeliveryTimeInput = !rowData.planDeliveryTimeInput;
  }

  public openBatchEdit(): void {
    this.batchEditVisible = true;
  }
  /**
   * 批量设置时间
   */
  public batchEditSuccess(planDeliveryTime: string): void {
    if (this.selectedProducts.length > 0) {
      this.selectedProducts.forEach(item => {
        item.planDeliveryTime = planDeliveryTime;
      });
    } else {
      this.tableOptions.data.forEach(item => {
        item.planDeliveryTime = planDeliveryTime;
      });
    }
  }

  private batchDeleteProduct(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 未保存到数据库的数据，使用项次Id进行删除即可
          const itemIds = this.selectedProducts.filter(x => !x.id).map(x => x.itemId);
          if (itemIds.length > 0) {
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法
            const filterData = this.tableData.filter(x => !itemIds.includes(x.itemId!));
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          // 已保存到数据库的数据，需要先调用接口进行删除
          const ids = this.selectedProducts.filter(x => !!x.id).map(x => x.id!);
          if (ids.length > 0) {
            await deliveryNoticeService.batchDeleteDeliveryProduct(ids);
            const filterData = this.tableData.filter(x => !ids.includes(x.id!));
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          // 处理勾选问题
          (this.$refs.deliveryProductTable as OsTable).clearSelection();
          this.selectedProducts = [];

          // 处理分页
          this.pagingData();

          // 计算项次剩余可发货数量
          this.$emit('delivery-count-change');
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openAddProductDialog(): void {
    this.addProductDialog = true;
  }

  @Watch('selectedProducts')
  private handleSelectedChanged(value: Array<DeliveryShopList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType === 'batchDelete') {
        x.disabled = value.length === 0;
      }
    });
  }

  private calculateRemainCount(itemId: number, count: number): number {
    const item = this.productDeliveryCount.find(x => x.itemId === itemId);
    item!.sentCount += count;
    return item!.total - item!.sentCount;
  }

  private initDynamicProps(x: DeliveryShopItemRelList | ProjectProductDelivery): void {
    Vue.set(x, 'showSendCountInput', false);
    Vue.set(x, 'planDeliveryTimeInput', false);
    if (!x.hasOwnProperty('planDeliveryTime')) {
      Vue.set(x, 'planDeliveryTime', x.requiredDeliveryTime);
    }
  }

}
