import { DialogMixin } from '@/mixins/dialog';
import { limitFutureForTimePicker, translation, messageErrors } from '@/utils';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class BatchEdit extends mixins(DialogMixin) {
  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public form = {
    planDeliveryTime: null
  };

  public formRules: { [P in keyof Partial<{ planDeliveryTime: string }>]: Array<Object> } = {
    planDeliveryTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('projectShop.selectPlannedShippingTime')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ]
  };
  public async submit(): Promise<void> {
    (this.$refs.batchEditForm as ElForm).validate(async (valid: boolean) => {
      console.log(valid);
      if (!valid) {
        return;
      }
      this.setLoading(true);
      try {
        this.$emit('batch-edit', this.form.planDeliveryTime);
        Message.success(translation('operationRes.editSuccess'));
        this.closeDialog();
      } catch (error) {
        messageErrors(error);
      } finally {
        this.setLoading(false);
      }
    });
  }

  public dialogClosed(): void {
    (this.$refs.batchEditForm as ElForm).resetFields();
    this.form = {
      planDeliveryTime: null
    };
  }
}
