import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import OsTable, { OsTableOption, OsTableColumn, RowOperation } from '@/components/os-table/os-table';
import { PagingMixin } from '@/mixins/paging';
import { DeliveryShopList, ImportRes, ProductDeliveryCount, ProjectShopResource } from '@/resource/model';
import { downloadFileByBlob, handleImportError, messageError, showWarningConfirm, translation } from '@/utils';
import { Message } from 'element-ui';
import { mixins } from 'vue-class-component';
import { Component, InjectReactive, PropSync, Watch } from 'vue-property-decorator';
import SelectProjectShop from './select-shop/select-shop.vue';
import CustomSite from './custom-site/custom-site.vue';
import { uniqueId } from 'lodash-es';
import deliveryNoticeService from '@/api/logistics-management/delivery-notice';
import { ApiResponse } from '@/api/axios';
import { ImportFile } from '@/views/dialogs';
import { DeliveryModeEnum } from '@/resource/enum';
import BatchEdit from '../batch-edit/batch-edit.vue';
@Component({
  components: { SelectProjectShop, CustomSite, ImportFile, BatchEdit }
})
export default class ShopDeliveryDetails extends mixins(PagingMixin) {
  @PropSync('shopDeliveries', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  public tableData!: Array<Partial<DeliveryShopList>>;

  public batchEditVisible: boolean = false;

  public isEdit!: boolean;

  public tableOptions: OsTableOption<Partial<DeliveryShopList>> = {
    loading: false,
    data: [],
    fit: true,
    closeAdaptiveHeight: true,
    rowKey: () => {
      return 'projectShopId';
    }
  };

  public columnOptions: Array<OsTableColumn<DeliveryShopList>> = [
    {
      type: 'selection',
      prop: 'projectShopId',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'shopName',
      label: 'addDeliveryNotice.deliverySite',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'address',
      label: 'addDeliveryNotice.deliveryAddress',
      minWidth: '200px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return `${(row as DeliveryShopList).province}
        ${(row as DeliveryShopList).city ? (row as DeliveryShopList).city : ''}
        ${(row as DeliveryShopList).district ? (row as DeliveryShopList).district : ''}
        ${(row as DeliveryShopList).address}`;
      }
    },
    {
      prop: 'receiver',
      label: 'addDeliveryNotice.receiver',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'receiverTel',
      label: 'addDeliveryNotice.receiverTel',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'sendMethod',
      label: 'logistics.deliveryMode',
      showOverflowTooltip: true,
      minWidth: '120px',
      formatter: (row: Object): string => {
        return translation(`deliveryMode.${DeliveryModeEnum[(row as ProjectShopResource).sendMethod]}`);
      }
    },
    {
      prop: 'remark',
      label: 'logistics.deliveryRemark',
      showOverflowTooltip: true,
      minWidth: '200px'
    }
  ];

  public operationOptions: Array<OperationOption> = [
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.addSite',
      operationType: 'add',
      icon: 'el-icon-circle-plus-outline',
      handleClick: (): void => {
        this.openAddShopDialog();
      }
    },
    // {
    //   type: 'primary',
    //   slot: 'start',
    //   label: 'addDeliveryNotice.customSite',
    //   operationType: 'customSite',
    //   icon: 'el-icon-circle-plus-outline',
    //   handleClick: (): void => {
    //     this.openCustomSiteDialog();
    //   }
    // },
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.importDeliveryDetails',
      operationType: 'import',
      icon: 'el-icon-upload2',
      permissionCode: 'delivery:notice:importData',
      handleClick: (): void => {
        this.openImportShopDialog();
      }
    },
    {
      type: 'danger',
      slot: 'start',
      label: 'button.batchDelete',
      operationType: 'batchDelete',
      icon: 'el-icon-delete',
      permissionCode: 'delivery:notice:deleteShop',
      plain: true,
      disabled: true,
      handleClick: (): void => {
        this.batchDeleteShop();
      }
    },
    {
      type: 'primary',
      slot: 'start',
      label: 'addDeliveryNotice.batchEdit',
      operationType: 'batchEdit',
      icon: 'el-icon-edit',
      plain: true,
      handleClick: (): void => {
        this.openBatchEdit();
      }
    }
  ];

  public rowOperationOptions: RowOperation<DeliveryShopList> | null = {
    fixed: 'right',
    width: '120px',
    operations: [
      {
        operationType: 'edit',
        type: 'text',
        label: 'button.edit',
        icon: 'el-icon-edit',
        handleClick: (item): void => {
          this.openCustomSiteDialog(item);
        }
      }
    ]
  };

  public selectedShops: Array<DeliveryShopList> = [];

  public selectShopDialog = false;

  public customSiteDialog = false;

  public importDialog = false;

  public shopDelivery: DeliveryShopList | null = null;

  @InjectReactive()
  private projectId!: number;


  /**
   * 产品项次剩余可发货数量
   */
  @PropSync('productDeliveryRecord', {
    required: true,
    type: Array,
    default: () => {
      return [];
    }
  })
  private productDeliveryCount!: Array<ProductDeliveryCount>;

  public pagingData(): void {
    this.totalData = this.tableData.length;
    this.tableOptions.data = this.tableData.slice(
      (this.paging.currentPage - 1) * this.paging.showCount,
      this.paging.currentPage * this.paging.showCount
    );
  }

  public shopClick(deliveryShop: DeliveryShopList): void {
    this.$emit('shop-click', deliveryShop);
  }

  public handleShopSelection(shops: Array<DeliveryShopList>): void {
    this.selectedShops = shops;
  }

  public async handleSelectShopConfirm(shops: Array<ProjectShopResource>): Promise<void> {
    // 表格中已添加的门店
    const addedShopIds = this.tableData.map(x => x.projectShopId!);
    // 可添加的门店
    const toAddShops = shops.filter(x => !addedShopIds.includes(x.id!));
    if (toAddShops.length === 0) {
      return;
    }
    try {
      this.tableOptions.loading = true;
      // 默认会根据项目门店id去查项目交货明细下配置的项次
      // 该接口同时返回项目门店相关信息，所以直接加进去就好
      const projectShopIds = toAddShops.map(x => x.id!);
      const shopsRes = await deliveryNoticeService.getDeliveryShopItemsByShops(projectShopIds);

      this.tableData.push(
        ...shopsRes.map(x => {
          this.$emit('add-shop-products', x.shopItemReList);

          x.uuid = this.createUuid();
          return x;
        })
      );
    } catch (error) {
      messageError(error);
    } finally {
      this.tableOptions.loading = false;
    }

    // 添加完门店后回到第一页
    this.paging.currentPage = 1;
    this.pagingData();
  }

  /**
   * 处理添加自定义发货地点成功事件
   * @param deliveryShop 自定义交货地点
   */
  public handleAddCustomSite(deliveryShop: DeliveryShopList): void {
    deliveryShop.uuid = this.createUuid();
    deliveryShop.shopItemReList = [];
    this.tableData.unshift(deliveryShop);
    this.pagingData();
  }

  public downloadTemplate(): void {
    (this.$refs.importDialog as ImportFile).setDownloadLoading(true);
    deliveryNoticeService
      .downloadTemplate(this.projectId)
      .then((blob: any) => {
        downloadFileByBlob(`${translation('addDeliveryNotice.templateName')}.xlsx`, blob);
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        (this.$refs.importDialog as ImportFile).setDownloadLoading(false);
      });
  }

  public importDeliveryDetails(path: string): void {
    deliveryNoticeService
      .importDeliveryDetails(path, this.projectId)
      .then(res => {
        Message.success(translation('dialog.importSuccess'));
        this.importDialog = false;
        this.tableData = res.map(x => {
          x.uuid = this.createUuid();
          return x;
        });
      })
      .catch((error: ApiResponse<ImportRes>) => {
        handleImportError(error);
        (this.$refs.importDialog as ImportFile).setLoading(false);
      });
  }

  public openBatchEdit(): void {
    this.batchEditVisible = true;
  }
  /**
   * 批量设置时间
   */
  public batchEditSuccess(planDeliveryTime: string): void {
    if (this.selectedShops.length > 0) {
      this.selectedShops.forEach(shop => {
        shop.shopItemReList.forEach(item => {
          item.planDeliveryTime = planDeliveryTime;
        });
      });
    } else {
      this.tableData.forEach(shop => {
        shop.shopItemReList?.forEach(item => {
          item.planDeliveryTime = planDeliveryTime;
        });
      });
    }
  }

  private openCustomSiteDialog(shop?: DeliveryShopList): void {
    this.customSiteDialog = true;
    this.shopDelivery = shop ?? null;
  }

  private openImportShopDialog(): void {
    this.importDialog = true;
  }

  private batchDeleteShop(): void {
    showWarningConfirm(translation('tip.confirmDelete'))
      .then(async () => {
        try {
          // 未保存到数据库的数据，使用uuid进行删除即可
          const uuids = this.selectedShops.filter(x => !x.id).map(x => x.uuid);
          if (uuids.length > 0) {
            // 对于prop.sync的数据只能使用vue包装过的数组处理方法
            const filterData = this.tableData.filter(x => !uuids.includes(x.uuid!));
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          // 已保存到数据库的数据，需要先调用接口进行删除
          const ids = this.selectedShops.filter(x => !!x.id).map(x => x.id);
          if (ids.length > 0) {
            await deliveryNoticeService.batchDeleteDeliveryShop(ids);
            const filterData = this.tableData.filter(x => !ids.includes(x.id!));
            this.tableData.splice(0, this.tableData.length);
            this.tableData.push(...filterData);
          }

          this.$emit('shop-deleted', this.selectedShops);

          // 处理勾选问题
          (this.$refs.shopTable as OsTable).clearSelection();
          this.selectedShops = [];

          // 处理分页
          this.pagingData();
        } catch (error) {
          messageError(error);
        }
      })
      .catch(() => {
        Message.info(translation('operationRes.cancelDelete'));
      });
  }

  private openAddShopDialog(): void {
    this.selectShopDialog = true;
  }

  @Watch('selectedShops')
  private handleSelectedChanged(value: Array<DeliveryShopList>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType === 'batchDelete') {
        x.disabled = value.length === 0;
      }
    });
  }

  private createUuid(): string {
    return uniqueId('shop');
  }

  @Watch('tableData')
  private handleDataChanged(values: Array<DeliveryShopList>): void {
    values.forEach(x => {
      x.uuid = this.createUuid();
    });
    this.pagingData();
  }
}
