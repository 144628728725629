import { supplierService } from '@/api';
import {
  DeliveryNoticeDetail,
  DeliveryShopItemRelList,
  DeliveryShopList,
  ProductDeliveryCount,
  SaveDeliveryNotice,
  SupplierResource
} from '@/resource/model';
import { debounce, limitFutureForTimePicker, messageError, messageErrors, showConfirm, translation } from '@/utils';
import { Component, ProvideReactive, Vue } from 'vue-property-decorator';
import ShopDeliveryDetails from './shop-delivery-details/shop-delivery-details.vue';
import ProductDeliveryDetails from './product-delivery-details/product-delivery-details.vue';
import { deliveryNoticeService } from '@/api/';
import { ElForm } from 'element-ui/types/form';
import { Message } from 'element-ui';
import { Route } from 'vue-router';
import { TagsViewModule } from '@/store/modules/tags-view';
import { ApiError } from '@/api/axios';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'AddDeliveryNotice',
  components: { ShopDeliveryDetails, ProductDeliveryDetails }
})
export default class AddDeliveryNotice extends Vue {
  public id = Number(this.$route.query.id);

  public title = '';

  public suppliers: Array<SupplierResource> = [];

  public deliveryProductKey = 'uuid';

  public saveLoading = false;

  /**
   * 记录产品已发货数量
   */
  public productDeliveryRecord: Array<ProductDeliveryCount> = [];

  public deliveryNoticeForm: Partial<SaveDeliveryNotice> & {
    projectName: string;
    code?: string;
  } = {
    projectId: undefined,
    projectName: '',
    projectCode: '',
    customerId: undefined,
    customerName: '',
    supplierName: '',
    supplierId: undefined,
    remark: '',
    deliveryRemark: '',
    shopList: [],
    requiredDeliveryTime: '',
    requiredArriveTime: ''
  };

  public formRules: {
    [propName in keyof Pick<SaveDeliveryNotice, 'supplierId' | 'requiredDeliveryTime' | 'requiredArriveTime'>]: Array<
      object
    >;
  } = {
    supplierId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('addDeliveryNotice.selectSupplier')));
            return;
          }
          callback();
        },
        trigger: 'change'
      }
    ],
    requiredDeliveryTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('orderChange.requiredDeliveryTimeInputTip')));
            return;
          }
          const { requiredArriveTime } = this.deliveryNoticeForm;

          if (requiredArriveTime && new Date(value) > new Date(requiredArriveTime)) {
            callback(new Error(translation('orderChange.timeErrorTip')));
            return;
          }
          (this.$refs.deliveryNoticeForm as ElForm).clearValidate();
          callback();
        },
        trigger: 'change'
      }
    ],
    requiredArriveTime: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('orderChange.requiredArriveTimeInputTip')));
            return;
          }
          const { requiredDeliveryTime } = this.deliveryNoticeForm;
          if (requiredDeliveryTime && new Date(value) < new Date(requiredDeliveryTime)) {
            callback(new Error(translation('orderChange.timeErrorTip')));
            return;
          }
          (this.$refs.deliveryNoticeForm as ElForm).clearValidate();
          callback();
        },
        trigger: 'change'
      }
    ]
  };

  public productDeliveries: Array<DeliveryShopItemRelList> = [];

  /**
   * 当前正在进行分配发货产品的门店名称
   */
  public currentDeliveryShopName = '';

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  private deliveryNoticeInfo: DeliveryNoticeDetail | null = null;

  @ProvideReactive()
  private projectId = 0;

  private needReloadList = false;

  public activated(): void {
    this.deliveryProductKey = 'uuid';
    this.currentDeliveryShopName = '';
    this.id = Number(this.$route.query.id);
    //  编辑
    if (!isNaN(this.id)) {
      this.title = 'addDeliveryNotice.editDeliveryNotice';
      this.getDeliveryNoticeDetails();
    }
    // 新增
    if (isNaN(this.id)) {
      if (isNaN(this.id) && this.deliveryNoticeInfo) {
        TagsViewModule.RefreshCurrentTag();
        return;
      }
      this.title = 'addDeliveryNotice.addDeliveryNotice';
      this.initForAdd();
    }
  }

  public async created(): Promise<void> {
    this.getSuppliers();
    if (isNaN(this.id)) {
      this.title = 'addDeliveryNotice.addDeliveryNotice';
      this.initForAdd();
      return;
    } else {
      this.title = 'addDeliveryNotice.editDeliveryNotice';
      this.getDeliveryNoticeDetails();
    }
  }

  public shopClick(deliveryShop: DeliveryShopList): void {
    // 切换门店时计算项次已发货数量和剩余可发货数量
    this.handleProductSentCount();
    deliveryShop.shopItemReList.forEach(x => {
      x.remainCount = this.calculateRemainCount(x);
    });
    this.productDeliveries = deliveryShop.shopItemReList;
    this.currentDeliveryShopName = ` —— ${deliveryShop.shopName}`;
    this.deliveryProductKey = deliveryShop.uuid;
  }

  public shopDeleted(deletedShops: Array<DeliveryShopList>): void {
    const deletedShopsUuids = deletedShops.map(x => x.uuid);
    // 如果正在展示的产品明细属于被删除的门店，则清除产品明细展示
    if (deletedShopsUuids.includes(this.deliveryProductKey)) {
      this.deliveryProductKey = 'uuid';
    }
    this.handleProductSentCount();

    if(this.deliveryNoticeForm!.shopList!.length > 0) {
      this.shopClick(this.deliveryNoticeForm!.shopList![0]);
    }
    
  }

  /**
   * 计算项次已发货的产品数量
   */
  public handleProductSentCount(): void {
    this.productDeliveryRecord = [];
    try {
      for (const shop of this.deliveryNoticeForm.shopList!) {
        for (const product of shop.shopItemReList) {
          const index = this.productDeliveryRecord.findIndex(x => x.itemId === product.itemId);
          // 如果已存在该项次记录，则累加已发货数量
          if (index !== -1) {
            this.productDeliveryRecord[index].sentCount += Number(product.count);
          } else {
            product.remainCount = Number(product.productCount) - Number(product.count);
            this.productDeliveryRecord.push({
              itemId: product.itemId,
              sentCount: Number(product.count),
              total: Number(product.productCount)
            });
          }
        }
      }
    } catch (err) {}
  }

  public saveDeliveryNotice(): void {
    (this.$refs.deliveryNoticeForm as ElForm).validate(async (valid: boolean, object: any) => {
      if (!valid) {
        Message.error((object[Object.keys(object)[0]][0]).message);
        return;
      }

      if (!this.verifyDeliveryDetails()) {
        return;
      }
      try {
        this.saveLoading = true;
        const supplier = this.suppliers.find(x => x.id === this.deliveryNoticeForm.supplierId);
        this.deliveryNoticeForm.supplierName = supplier!.companyName;
        if (!this.id) {
          await deliveryNoticeService.post(this.deliveryNoticeForm as SaveDeliveryNotice);
        } else {
          await deliveryNoticeService.put((this.deliveryNoticeForm as any) as DeliveryNoticeDetail);
          this.needReloadList = true;
        }
        Message.success(translation('operationRes.saveSuccess'));
        this.$router.go(-1);
        TagsViewModule.DelView(this.$route);
      } catch (error) {
        messageErrors(error);
      } finally {
        this.saveLoading = false;
      }
    });
  }

  public saveAndPushDeliveryNotice(): void {
    (this.$refs.deliveryNoticeForm as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }

      if (!this.verifyDeliveryDetails()) {
        return;
      }
      try {
        this.saveLoading = true;
        const supplier = this.suppliers.find(x => x.id === this.deliveryNoticeForm.supplierId);
        this.deliveryNoticeForm.supplierName = supplier!.companyName;

        if (!this.id) {
          await deliveryNoticeService.saveAndPush(this.deliveryNoticeForm as SaveDeliveryNotice);
        } else {
          await deliveryNoticeService.editAndPush((this.deliveryNoticeForm as any) as DeliveryNoticeDetail);
          this.needReloadList = true;
        }
        Message.success(translation('operationRes.saveSuccess'));
        this.$router.go(-1);
        TagsViewModule.DelView(this.$route);
      } catch (error) {
        messageErrors(error);
      } finally {
        this.saveLoading = false;
      }
    });
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    if (to.path === '/delivery-notice') {
      to.meta!.needReload = this.needReloadList;
    }
    next();
  }

  /**
   * 批量添加门店产品数据处理
   * @param products 新添加门店产品集合
   */
  public addShopProducts(products: Array<DeliveryShopItemRelList>): void {
    if(products.length <= 0) {
      return;
    }

    products.forEach(x => {
      this.addShopProduct(x);
    });
  }

  /**
   * 新添加门店产品逻辑处理
   * @param product 添加产品
   */
  public addShopProduct(product: DeliveryShopItemRelList): void {
    const index = this.productDeliveryRecord.findIndex(x => x.itemId === product.itemId);
    // 如果已存在该项次记录，则累加已发货数量
    if (index !== -1) {
      const record  = this.productDeliveryRecord[index];
      const remainCount = record.total - record.sentCount;
      if(remainCount < product.count) {
        product.count = remainCount;
        product.remainCount = 0;
      }
      this.productDeliveryRecord[index].sentCount += Number(product.count);
    } else {
      product.remainCount = Number(product.productCount) - Number(product.count);
      this.productDeliveryRecord.push({
        itemId: product.itemId,
        sentCount: Number(product.count),
        total: Number(product.productCount)
      });
    }
  }

  private getSuppliers(): void {
    supplierService
      .listEnable()
      .then(res => {
        this.suppliers = res ?? [];
        // 新建时默认选中供应商
        if(!this.id && this.suppliers.length > 0) {
          this.deliveryNoticeForm.supplierId = this.suppliers[this.suppliers.length - 1].id;
        }
        
      })
      .catch(error => {
        messageError(error);
      });
  }

  /**
   * 验证发货明细，是否合法
   * @returns
   */
  private verifyDeliveryDetails(): boolean {
    if (this.deliveryNoticeForm.shopList?.length === 0) {
      Message.warning(`${translation('addDeliveryNotice.pleaseAddstore')}`);
      return false;
    }

    const verifyFailedShop = this.deliveryNoticeForm.shopList?.find(
      x => x.shopItemReList.filter(y => y.count <= 0).length > 0
    );
    if (verifyFailedShop) {
      this.productDeliveries = verifyFailedShop.shopItemReList;
      this.currentDeliveryShopName = ` —— ${verifyFailedShop.shopName}`;
      this.deliveryProductKey = verifyFailedShop.uuid;
      Message.warning(`${translation('addDeliveryNotice.verifyCountFailed')} —— ${verifyFailedShop.shopName}`);
      return false;
    }

    const emptyShop = this.deliveryNoticeForm.shopList?.find(x => x.shopItemReList.length === 0);
    if (emptyShop) {
      this.productDeliveries = emptyShop.shopItemReList;
      this.currentDeliveryShopName = ` —— ${emptyShop.shopName}`;
      this.deliveryProductKey = emptyShop.uuid;
      Message.warning(`${emptyShop.shopName} —— ${translation('addDeliveryNotice.verifyEmptyFailed')}`);
      return false;
    }
    if (this.verifyDeliveryTime()) {
      return false;
    }
    return true;
  }

  /**
   * 校验项次出货时间合法性
   * 规则： 计划出货时间 <= 要求出货时间
   * @param
   * @returns
   */

  private verifyDeliveryTime(): boolean {
    const errors: Array<ApiError> = [];
    this.deliveryNoticeForm.shopList?.forEach(shop => {
      let message = '';
      shop.shopItemReList?.forEach(item => {
        if (new Date(item.planDeliveryTime!) < new Date(this.deliveryNoticeForm.requiredDeliveryTime!)) {
          message = `${message} ${item.itemCode} `;
        }
      });
      if (message) {
        errors.push({
          message: `【${shop.shopName}】: ${message} 计划出货时间早于要求出货时间`,
          level: 1
        });
      }
    });

    if (errors.length > 0) {
      messageErrors(errors);
    }
    return errors.length > 0;
  }

  private calculateRemainCount(shopItem: DeliveryShopItemRelList): number {
    const item = this.productDeliveryRecord.find(x => x.itemId === shopItem.itemId);
    return item!.total - item!.sentCount;
  }

  /**
   * 初始化添加模式下的表单数据
   */
  @debounce()
  private async initForAdd(): Promise<void> {
    // 从项目添加发货通知单时，参数是从url拿的，而编辑时通过getById获得
    this.deliveryNoticeForm.projectId = Number(this.$route.query.projectId);
    this.projectId = this.deliveryNoticeForm.projectId;
    this.deliveryNoticeForm.projectName = this.$route.query.projectName as string;
    this.deliveryNoticeForm.projectCode = this.$route.query.projectCode as string;
    this.deliveryNoticeForm.customerId = Number(this.$route.query.customerId);
    this.deliveryNoticeForm.customerName = this.$route.query.customerName as string;
    this.deliveryNoticeForm.shopList = [];

    try {
      // 询问用户是否从项目中自动导入交货明细数据
      await showConfirm(translation('addDeliveryNotice.isInitDeliveryDataForProject'), 'info');
      this.initConfigDeliveryData();
    } catch (error) {
      if (error !== 'cancel') messageError(error);
    }
  }
  /**
   * 获取详情
   */
  @debounce()
  private getDeliveryNoticeDetails(): void {
    deliveryNoticeService
      .getById(this.id)
      .then(res => {
        this.deliveryNoticeInfo = res;
        this.projectId = res.projectId;
        Object.assign(this.deliveryNoticeForm, res);

        this.deliveryNoticeForm.shopList?.splice(0, 0);
        // 每次切换 产品明细展示第一
        this.$nextTick(() => {
          if (this.deliveryNoticeForm!.shopList!.length > 0) {
            this.shopClick(this.deliveryNoticeForm!.shopList![0]);
          }
        });
      })
      .catch(error => {
        messageError(error);
      });
  }

  private initConfigDeliveryData(): void {
    deliveryNoticeService
      .getDeliveryDetailsByProject(this.projectId)
      .then(res => {
        this.deliveryNoticeForm.shopList = res;
        // 每次切换 产品明细展示第一
        this.$nextTick(() => {
          this.productDeliveries = [];
          if (this.deliveryNoticeForm!.shopList!.length > 0) {
            this.shopClick(this.deliveryNoticeForm!.shopList![0]);
          }
        });
      })
      .catch(error => {
        messageError(error);
      });
  }
}
